import React, { useState, useEffect, useMemo } from "react";
import { Base64 } from 'js-base64';
import { API_BASE_URL } from "../App";
import "../index.css";
import "../TableSection.css";

function AiReport({ symbol = null }) {
    const [reportHtml, setReportHtml] = useState(""); // State to store the report HTML
    const [loading, setLoading] = useState(true); // State to manage loading state
    const [error, setError] = useState(null); // State to handle errors

    // Create API Endpoint
    const apiEndpoint = useMemo(() => {
        if (!symbol) {
            console.error("Symbol is a mandatory parameter");
            return null;
        }

        const filters = { symbol: symbol };
        const encodedFilters = Base64.encodeURL(JSON.stringify(filters));
        return `${API_BASE_URL}/reports/?filters=${encodedFilters}`;
    }, [symbol]);

    useEffect(() => {
        const fetchData = async () => {
            if (!apiEndpoint) return; // Exit early if no endpoint

            try {
                setLoading(true);
                const response = await fetch(apiEndpoint);
                const data = await response.json();

                let reportHtml = data?.reports?.kuber_analytics?.text || null;

                // Clean up HTML styles
                if (reportHtml) {
                    reportHtml = reportHtml.replace(/style={{color: 'green'}}/g, 'style="color: green;"');
                    reportHtml = reportHtml.replace(/style={{color: 'red'}}/g, 'style="color: red;"');
                }

                setReportHtml(reportHtml); // Set the cleaned HTML response
            } catch (error) {
                console.error("Error fetching report:", error);
                setError("Failed to load report");
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, [apiEndpoint]);

    if (loading) {
        return <div>Loading...</div>; // Loading indicator
    }

    if (error) {
        return <div>Error: {error}</div>; // Error message
    }

    // Check if the report is empty or null and show "Coming Soon" message
    if (!reportHtml) {
        return (
            <div className="section-wrapper">
                <div className="section">
                    <h2>Integrated Financial Report</h2>
                    <p>Coming Soon</p>
                </div>
            </div>
        );
    }

    return (
        <div className="section-wrapper">
            <div className="section">
                <h2>Integrated Financial Report</h2>
                {/* Rendering HTML content safely */}
                <div dangerouslySetInnerHTML={{ __html: reportHtml }} />
            </div>
        </div>
    );
}

export default AiReport;