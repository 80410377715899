import React, { useState, useEffect, useMemo } from "react";
import { Base64 } from 'js-base64';
import { API_BASE_URL } from "../App";
import "../index.css";
import "../TableSection.css";

function BrokerageReports({ symbol }) {  
    const [tableData, setTableData] = useState([]);

    // Construct the API endpoint with encoded filter parameters
    const apiEndpoint = useMemo(() => {
        if (!symbol) {
            console.error("Symbol is a mandatory parameter");
            return null;
        }

        const filters = {
            symbol: symbol  // Ensure symbol is passed as a filter
        };

        // Convert filters to JSON and encode in base64
        const encodedFilters = Base64.encodeURL(JSON.stringify(filters));

        // Return the endpoint with the encoded filters as a query parameter
        return `${API_BASE_URL}/company-info/?filters=${encodedFilters}`;
    }, [symbol]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch(apiEndpoint);
                const data = await response.json();

                // Extract report data from the API response
                const reports = data.reports?.brokerage_reports || {};

                // Convert reports object into an array of { broker, date, link } objects
                let formattedReports = [];
                Object.keys(reports).forEach(broker => {
                    Object.keys(reports[broker]).forEach(date => {
                        formattedReports.push({
                            broker,
                            date,
                            link: reports[broker][date].url
                        });
                    });
                });

                // Sort the reports by date in descending order
                formattedReports.sort((a, b) => new Date(b.date) - new Date(a.date));

                setTableData(formattedReports); // Update state with the report data
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        };

        if (apiEndpoint) {
            fetchData();
        }
    }, [apiEndpoint]);

    return (
        <div className="table-section">
            <div className="fixed-heading">
                <h2>Brokerage Reports</h2>
            </div>
            <div className="table-container">
                <table>
                    <thead>
                        <tr>
                            <th>Broker Name</th>
                            <th>Report Link</th>
                            <th>Date</th>
                        </tr>
                    </thead>
                    <tbody>
                        {tableData.length > 0 ? (
                            tableData.map((row, rowIndex) => (
                                <tr key={rowIndex}>
                                    <td>{row.broker}</td>
                                    <td>
                                        <a href={row.link} target="_blank" rel="noopener noreferrer">
                                            View Report
                                        </a>
                                    </td>
                                    <td>{row.date}</td>
                                </tr>
                            ))
                        ) : (
                            <tr>
                                <td colSpan={3}>No Reports Available</td>
                            </tr>
                        )}
                    </tbody>
                </table>
            </div>
        </div>
    );
}

export default BrokerageReports;