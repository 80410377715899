import React, { useState, useEffect, useMemo, useCallback } from "react";
import { Base64 } from 'js-base64';
import { Link } from "react-router-dom";
import { formatDateTime, API_BASE_URL } from "../App";
import "../index.css";
import "../TableSection.css";

function BigDeals({ symbol = null }) {
    const [tableData, setTableData] = useState([]);
    const [loading, setLoading] = useState(true);

    // Specify the fields you want to display
    const displayFields = useMemo(() => ["symbol", "message", "created_at"], []);

    // Construct the API endpoint with encoded filter parameters
    const apiEndpoint = useMemo(() => {
        const filters = {
            symbol: symbol || undefined,  // Include symbol if provided
        };

        // Remove undefined keys from the filters object
        Object.keys(filters).forEach(key => filters[key] === undefined && delete filters[key]);

        if (Object.keys(filters).length > 0) {
            const encodedFilters = Base64.encodeURL(JSON.stringify(filters));
            return `${API_BASE_URL}/deals/?filters=${encodedFilters}`;
        }
        return `${API_BASE_URL}/deals/`;
    }, [symbol]);

    const fetchData = useCallback(async () => {
        setLoading(true);
        try {
            const response = await fetch(apiEndpoint);
            const data = await response.json();
            setTableData(data);
        } catch (error) {
            console.error("Error fetching data:", error);
        } finally {
            setLoading(false);
        }
    }, [apiEndpoint]);

    useEffect(() => {
        if (!apiEndpoint) return;

        fetchData();
        const intervalId = setInterval(fetchData, 300000);  // Poll every 2 minutes
        return () => clearInterval(intervalId);  // Clean up interval on component unmount
    }, [apiEndpoint, fetchData]);

    if (loading) {
        return <div>Loading...</div>;  // Optional: Add a loading spinner
    }

    return (
        <div className="table-section">
            <div className="fixed-heading">
                <h2>High Value Deals</h2>
            </div>
            <div className="table-container">
                <table>
                    <thead>
                        <tr>
                            {displayFields.map((field, index) => (
                                <th key={index}>{field.toUpperCase()}</th>
                            ))}
                        </tr>
                    </thead>
                    <tbody>
                        {tableData.length > 0 ? (
                            tableData.map((row, rowIndex) => (
                                <tr key={rowIndex} className={row.value > 100 ? "highlight-green" : ""}>
                                    {displayFields.map((field, colIndex) => (
                                        <td key={colIndex} style={field === "created_at" ? { whiteSpace: 'nowrap' } : {}}>
                                            {field === "symbol" ? (
                                                <Link to={`/fundamentals/${row[field]}`} target="_blank">
                                                    {row[field]}
                                                </Link>
                                            ) : field === "created_at" ? (
                                                formatDateTime(row[field])
                                            ) : (
                                                row[field]
                                            )}
                                        </td>
                                    ))}
                                </tr>
                            ))
                        ) : (
                            <tr>
                                <td colSpan={displayFields.length}>No Data.</td>
                            </tr>
                        )}
                    </tbody>
                </table>
            </div>
        </div>
    );
}

export default BigDeals;