// TradePopup.js
import React, { useState } from 'react';
import Modal from 'react-modal';
import TextField from '@atlaskit/textfield';
import Button from '@atlaskit/button/new';
import SearchBar from './searchBar';
import "../TradePopup.css";
import { API_BASE_URL } from '../App';

function TradePopup({ isOpen, onRequestClose }) {
    const [selectedStock, setSelectedStock] = useState('');
    const [entryPrice, setEntryPrice] = useState('');
    // const [stopLossPercent, setStopLossPercent] = useState('');
    // const [targetPercentage, setTargetPercentage] = useState('');
    const [stopLossPrice, setStopLossPrice] = useState('');
    const [targetPrice, setTargetPrice] = useState('');
    const [advisor, setAdvisor] = useState('StockBox');
    const [comments, setComments] = useState('');
    const [quantity, setQuantity] = useState('');
    const [subCategory, setSubCategory] = useState('Fundamental'); // Default value

    const handleSubmit = async (tradeType) => {
        const entryPriceValue = parseFloat(entryPrice) || 0;
        const quantityValue = parseFloat(quantity) || 10;
        // const stopLossPercentValue = parseFloat(stopLossPercent) || 0;
        // const targetPercentageValue = parseFloat(targetPercentage) || 0;
        const stopLossPriceValue = parseFloat(stopLossPrice) || 0;
        const targetPriceValue = parseFloat(targetPrice) || 0;

        if (!selectedStock || entryPriceValue <= 0) {
            alert("Please fill in the required fields correctly.");
            return;
        }

        const orderDetails = {
            stock: selectedStock,
            entry_price: entryPriceValue,
            quantity: quantityValue,
            stop_loss_price: stopLossPriceValue,
            target_price: targetPriceValue,            
            // stop_loss_percentage: stopLossPercentValue,
            // target_percentage: targetPercentageValue,
            call_type: tradeType,
            advisor: advisor,
            comments,
            sub_category: subCategory, // Include subCategory in the order details
        };

        try {
            const response = await fetch(`${API_BASE_URL}/place-order/`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(orderDetails),
            });

            if (response.ok) {
                onRequestClose();  // Close the popup after submission
            } else {
                alert('Failed to place order');
                onRequestClose();  // Close the popup after submission
            }
        } catch (error) {
            console.error('Error placing order:', error);
            alert('Error placing order');
        }
    };

    return (
        <Modal
            isOpen={isOpen}
            onRequestClose={onRequestClose}
            className="modal-content"
            overlayClassName="modal-overlay"
            contentLabel="Trade Popup"
            ariaHideApp={false}
        >
            <div className="modal-body">
                <div className="form-group">
                    <div className="search-bar-wrapper">
                        <SearchBar
                            onSelect={(symbol) => setSelectedStock(symbol)}
                            value={selectedStock}
                            navigateOnSelect={false}
                        />
                    </div>
                </div>
                <div className="form-group">
                    <TextField
                        id="entry-price"
                        className="form-control"
                        type="number"
                        value={entryPrice}
                        placeholder='entry price'
                        onChange={(e) => setEntryPrice(e.target.value)}
                    />
                </div>
                <div className="form-group">
                    <TextField
                        id="quantity"
                        className="form-control"
                        type="number"
                        value={quantity}
                        placeholder='quantity (default 10)'
                        onChange={(e) => setQuantity(e.target.value)}
                    />
                </div>
                <div className="form-group">
                    <TextField
                        id="advisor"
                        className="form-control"
                        value={advisor}
                        placeholder="advisor name ... ex broker name"
                        onChange={(e) => setAdvisor(e.target.value)}
                    />
                </div>
                <div className="form-group">
                    <TextField
                        id="stop-loss"
                        className="form-control"
                        type="number"
                        value={stopLossPrice}
                        placeholder='Stop Loss Price...'
                        onChange={(e) => setStopLossPrice(e.target.value)}
                    />

                    {/* <TextField
                        id="stop-loss"
                        className="form-control"
                        type="number"
                        value={stopLossPercent}
                        placeholder='Stop Loss % ...'
                        onChange={(e) => setStopLossPercent(e.target.value)}
                    /> */}
                </div>
                <div className="form-group">
                    <TextField
                        id="target"
                        className="form-control"
                        type="number"
                        value={targetPrice}
                        placeholder='Target Price ...'
                        onChange={(e) => setTargetPrice(e.target.value)}
                    />

                    {/* <TextField
                        id="target"
                        className="form-control"
                        type="number"
                        value={targetPercentage}
                        placeholder='Target % ...'
                        onChange={(e) => setTargetPercentage(e.target.value)}
                    /> */}
                </div>
                <div className="form-group">
                    <select
                        id="sub_category"
                        className="form-control"
                        value={subCategory}
                        onChange={(e) => setSubCategory(e.target.value)}
                    >
                        <option value="Fundamental">Fundamental</option>
                        <option value="Technical">Technical</option>
                        <option value="Derivative">Derivative</option>
                    </select>
                </div>
                <div className="form-group">
                    <TextField
                        id="comments"
                        className="form-control"
                        placeholder="Any comments..."
                        value={comments}
                        onChange={(e) => setComments(e.target.value)}
                    />
                </div>
            </div>
            <div className="modal-footer">
                <div className='button-wrapper' style={{ backgroundColor: 'green' }}>
                    <Button appearance="subtle" onClick={() => handleSubmit('buy')}>Buy</Button>
                </div>
                <div className='button-wrapper'>
                    <Button appearance="danger" onClick={() => handleSubmit('sell')}>Sell</Button>
                </div>
                <div className='button-wrapper'>
                    <Button appearance="warning" onClick={onRequestClose}>Cancel</Button>
                </div>
            </div>
        </Modal>
    );
}

export default TradePopup;