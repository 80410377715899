import React, { useState, useEffect } from "react";
import Plot from 'react-plotly.js';
import { Base64 } from 'js-base64'; // Make sure to install this package if not already installed
import { useLocation } from 'react-router-dom';
import { API_BASE_URL } from "../App";
import '../css/ChartSection.css';

function PairTradeStockChart() {
    const [chartData, setChartData] = useState(null);
    const [loading, setLoading] = useState(true);

    const location = useLocation(); // Get the current location
    const searchParams = new URLSearchParams(location.search); // Parse query parameters
    const call_id = searchParams.get("call_id"); // Get the 'call_id' from query parameters

    // Fetch chart data using the call_id
    useEffect(() => {
        const fetchData = async () => {
            if (!call_id) return;

            setLoading(true);
            try {
                const filters = {
                    call_id: call_id || undefined // Use call_id from the query params
                };
        
                // Convert filters to JSON and url-safe encode in base64
                const encodedFilters = Base64.encodeURL(JSON.stringify(filters));

                // Construct the API URL with encoded filters
                const apiUrl = `${API_BASE_URL}/trade-signals?filters=${encodedFilters}`;
                
                // Make the API request using fetch
                const response = await fetch(apiUrl);
                const data = await response.json();

                // Assuming 'charts.pair_trade' is a stringified JSON
                if (data[0] && data[0].charts && data[0].charts.pair_trade) {
                    const pairTradeChartData = JSON.parse(data[0].charts.pair_trade); // Parse the stringified JSON
                    setChartData(pairTradeChartData); // Set the parsed data to state
                } else {
                    console.error("No chart data available in the response");
                }
            } catch (error) {
                console.error("Error fetching chart data:", error);
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, [call_id]);

    if (loading) return <div>Loading...</div>;

    if (!chartData) return <div>No chart data available</div>;

    return (
        <div className="signal-chart-wrapper">
            <Plot
                data={chartData.data} // Assuming 'chartData' contains 'data' array for Plotly
                layout={chartData.layout} // Assuming 'chartData' contains 'layout' configuration
                config={{ responsive: true }}
                style={{ width: '100%', height: '100%' }}
                useResizeHandler={true}
            />
        </div>
    );
}

export default PairTradeStockChart;