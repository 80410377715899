// pageLayouts.js
import LiveNews from './widgets/LiveNews';
import TradingCalls from './widgets/TradingCalls';
import BigDeals from './widgets/BigDeals';
import MarketChat from './widgets/MarketChat';
import IncomeStatement from './widgets/incomeStatement';
import CashflowStatement from './widgets/cashflowStatement';
import BalanceSheetStatement from './widgets/balanceSheetStatement';
import FinancialRatiosStatement from './widgets/financialRatiosStatement';
import CommodityMetdata from './widgets/commodityMetadata';
import CompanyInfo from './widgets/companyInfo';
import HistoricalPriceTable from './widgets/historicalPriceTable';
import AdvisorStats from './widgets/advisorStats';
import StrategyKnowledge from './widgets/strategyKnowledgePage';
import StockTechnicalData from './widgets/stockTechnicalData';
import StockChart from './widgets/stockChart';
// import FinancialHighlights from './widgets/financialHighlights';
import SignalAnalysis from './widgets/signalAnalysis';
import StockPeerComparison from './widgets/stockPeerComparison';
import BrokerageReports from './widgets/brokerageReports';
import AiReport from './widgets/aiReport';
import ShareholdingReport from './widgets/shareholdingReport';
import TechnicalPriceMAChart from './widgets/TechnicalPriceMAChart'


const mainScreenLayout = {
  rows: 2,
  columns: 2,
  sheets: [
    <LiveNews />,
    <TradingCalls stage={'open'} sub_category={'Technical'} instrument_type={'STK'} />,
    <BigDeals />,
    <MarketChat />
  ],
}

const portfolioScreenLaytout = {
  rows: 2,
  columns: 4,
  sheets: [
    <TradingCalls stage={'portfolio'} pageName={'Holdings'} instrument_type={'STK'} />,
    <MarketChat />,
    <TradingCalls stage={'open'} sub_category={'Fundamental'} instrument_type={'STK'} />,
    <AdvisorStats />
  ],
  largeItems: [0, 1, 2, 3],
}

const commodityScreenLaytout = {
  rows: 3,
  columns: 4,
  sheets: [
    <CommodityMetdata />,
    <LiveNews category={'commodities'} />,
    <StockChart symbol={'GOLD'} />,
    <StockChart symbol={'SILVER'} />,
    <TradingCalls symbols={['GOLD', 'SILVER', 'CRUDEOIL', 'NATURALGAS', 'COPPER', 'ZINC', 'LEAD', 'ALUMINIUM']} />,
    <MarketChat keywords={['GOLD', 'SILVER', 'CRUDEOIL', 'NATURALGAS', 'COPPER', 'ZINC', 'LEAD', 'ALUMINIUM']} />,
  ],
  largeItems: [0, 1, 2, 3, 4, 5],
}

const screenersLaytout = {
  rows: 2,
  columns: 4,
  sheets: [
    <SignalAnalysis />,
    <AdvisorStats />
  ],
  largeItems: [0, 1, 2, 3]
}

const stockFundamentalLayout = {
  rows: 3,
  columns: 4,
  sheets: [
    <TradingCalls />,
    <AiReport />,
    <IncomeStatement />,
    <CashflowStatement />,
    <BalanceSheetStatement />,
    <FinancialRatiosStatement />,
  ],
  largeItems: [0, 1, 2, 3, 4, 5],
}

const companyInfoLayout = {
  rows: 3,
  columns: 4,
  sheets: [
    <CompanyInfo indicators={['longBusinessSummary']} />,
    <ShareholdingReport />,
    <StockChart />,
    <MarketChat />,
    <StockPeerComparison />,
    <BrokerageReports />,
  ],
  largeItems: [0, 1, 2, 3, 4, 5],
}

const companyAnalysisLayout = {
  rows: 3,
  columns: 4,
  sheets: [
    <TechnicalPriceMAChart />,
    // <ShareholdingReport/>,
    <StockChart />,
    // <MarketChat />,
    // <StockPeerComparison />,
    // <BusinessSummaryReport />,    
  ],
  largeItems: [0, 1, 2, 3, 4, 5],
}


export const pageLayouts = [
  mainScreenLayout, portfolioScreenLaytout, commodityScreenLaytout, stockFundamentalLayout,
  companyInfoLayout, companyAnalysisLayout, screenersLaytout
];