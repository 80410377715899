import React, { useState, useEffect, useMemo } from "react";
import Plot from 'react-plotly.js';
import { Base64 } from 'js-base64';
import { API_BASE_URL } from "../App";
import '../css/ChartSection.css';

function StockChart({ symbol }) {
    const [chartData, setChartData] = useState([]);
    const [loading, setLoading] = useState(true);

    // Memoized API endpoint
    const finalApiEndpoint = useMemo(() => {
        if (!symbol) return null;
        const filters = { symbol };
        const encodedFilters = Base64.encodeURL(JSON.stringify(filters));
        return `${API_BASE_URL}/historical-data/?filters=${encodedFilters}`;
    }, [symbol]);

    useEffect(() => {
        const fetchData = async () => {
            if (!finalApiEndpoint) return;
            setLoading(true);
            try {
                const response = await fetch(finalApiEndpoint);
                const data = await response.json();
                setChartData(data);
            } catch (error) {
                console.error("Error fetching chart data:", error);
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, [finalApiEndpoint]);

    if (loading) return <div>Loading...</div>;

    // Format the chart data for Plotly
    const formattedChartData = chartData.map(item => ({
        ...item,
        timestamp: new Date(item.timestamp),
    }));

    // Candlestick chart data
    const candlestickData = {
        x: formattedChartData.map(item => item.timestamp),
        open: formattedChartData.map(item => item.open),
        high: formattedChartData.map(item => item.high),
        low: formattedChartData.map(item => item.low),
        close: formattedChartData.map(item => item.close),
        type: 'candlestick',
        name: 'Price',
        increasing: { line: { color: '#26a69a' } }, // Green for increasing
        decreasing: { line: { color: '#ef5350' } }  // Red for decreasing
    };

    // Extract dividends data (if available)
    const dividendDates = formattedChartData
        .filter(item => item.dividend)  // Filter items that have dividends
        .map(item => ({
            x: new Date(item.timestamp),
            y: item.close, // Mark the dividend on the closing price
            text: `Dividend: ${item.dividend}`,
        }));

    // Volume line chart data
    const volumeData = {
        x: formattedChartData.map(item => item.timestamp),
        y: formattedChartData.map(item => item.volume),
        type: 'line',
        name: 'Volume',
        yaxis: 'y2',  // Set it to the second y-axis (for volume)
        marker: { color: 'rgba(31,119,180,0.7)' }, // Customize volume bar color
        opacity: 0.3
    };

    // Dividend markers data
    const dividendMarkers = {
        x: dividendDates.map(item => item.x),
        y: dividendDates.map(item => item.y),
        mode: 'markers',
        type: 'scatter',
        name: 'Dividend',
        marker: { color: 'orange', size: 10, symbol: 'diamond' },  // Customize marker
        text: dividendDates.map(item => item.text),  // Display dividend info on hover
        hoverinfo: 'text'
    };


    // Layout configuration
    const layout = {
        title: `${symbol} Historical Price and Volume`,
        xaxis: {
            type: 'date',
            tickformat: "%b %d, %H:%M",
            rangeslider: { visible: false },
        },
        yaxis: {
            title: 'Price',
            fixedrange: false,
            domain: [0.2, 1]  // Adjust the height of the candlestick chart
        },
        yaxis2: {
            title: 'Volume',
            overlaying: 'y',
            side: 'right',
            fixedrange: true,
            domain: [0, 0.2]  // Reserve space for the volume bar chart below the candlestick
        },
        showlegend: false,
        margin: { t: 40, r: 50, l: 50, b: 20 }
    };

    return (
        <div className="chart-wrapper">
            <Plot
                data={[candlestickData, volumeData, dividendMarkers]}  // Include both candlestick and volume data
                layout={layout}
                config={{ responsive: true }}
                style={{ width: '100%', height: '100%' }}
            />
        </div>
    );
}

export default StockChart;