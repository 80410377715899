import React, { useState, useEffect, useMemo, useCallback } from "react";
import { Base64 } from 'js-base64';
import { Link } from "react-router-dom";
import { formatDateTime, API_BASE_URL } from "../App";
import "../index.css";
import "../TableSection.css";

function LiveNews({ symbol = null, category = null }) {
    const [tableData, setTableData] = useState([]);
    const [loading, setLoading] = useState(true);

    // Memoized list of fields to display
    const displayFields = useMemo(() => ["symbol", "message", "created_at", "link"], []);

    // Construct the API endpoint with encoded filter parameters
    const apiEndpoint = useMemo(() => {
        const filters = { symbol, category };

        // Remove undefined keys
        const validFilters = Object.fromEntries(Object.entries(filters).filter(([_, value]) => value));

        if (Object.keys(validFilters).length > 0) {
            const encodedFilters = Base64.encodeURL(JSON.stringify(validFilters));
            return `${API_BASE_URL}/news/?filters=${encodedFilters}`;
        }
        return `${API_BASE_URL}/news/`;
    }, [symbol, category]);

    // Fetch data function
    const fetchData = useCallback(async () => {
        setLoading(true);
        try {
            const response = await fetch(apiEndpoint);
            const data = await response.json();
            setTableData(data); // Update state with fetched data
        } catch (error) {
            console.error("Error fetching data:", error);
        } finally {
            setLoading(false);
        }
    }, [apiEndpoint]);

    // Effect to fetch data and poll every 2 minutes
    useEffect(() => {
        let timeoutId;
        const fetchDataWithTimeout = async () => {
            await fetchData();
            timeoutId = setTimeout(fetchDataWithTimeout, 300000); // Poll every 5 minutes
        };
        fetchDataWithTimeout(); // Initial call to fetch data on mount
        return () => clearTimeout(timeoutId); // Cleanup timeout on unmount
    }, [fetchData]); // Ensure fetchData is memoized with useCallback

    const renderCell = (field, value, rowIndex) => {
        const cellStyle = field === "created_at" ? { whiteSpace: 'nowrap' } : {};
    
        // Create a map of field handlers
        const fieldMap = new Map([
            ["created_at", () => formatDateTime(value)],
            ["symbol", () => (
                <Link to={`/fundamentals/${value}`} target="_blank">
                    {value}
                </Link>
            )],
            ["link", () => (
                value ? <a href={value} target="_blank" rel="noopener noreferrer">🔗</a> : null
            )]
        ]);
    
        // Get the rendering logic for the field, or use default if the field is not in the map
        const content = fieldMap.has(field) ? fieldMap.get(field)() : value;
    
        return <td key={`${field}-${rowIndex}`} style={cellStyle}>{content}</td>;
    };
    
    // Render loading or table content
    return (
        <div className="table-section">
            <div className="fixed-heading">
                <h2>Live News</h2>
            </div>
            <div className="table-container">
                {loading ? (
                    <div>Loading...</div>
                ) : (
                    <table>
                        <thead>
                            <tr>
                                {displayFields.map((field, index) => (
                                    <th key={field}>{field.toUpperCase()}</th>
                                ))}
                            </tr>
                        </thead>
                        <tbody>
                            {tableData.length > 0 ? (
                                tableData.map((row, rowIndex) => (
                                    <tr key={rowIndex}>
                                        {displayFields.map((field) => renderCell(field, row[field], rowIndex))}
                                    </tr>
                                ))
                            ) : (
                                <tr>
                                    <td colSpan={displayFields.length}>No Data</td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                )}
            </div>
        </div>
    );
}

export default LiveNews;