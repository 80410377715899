import React, { useState, useEffect } from 'react';
import TextField from '@atlaskit/textfield';
import SearchIcon from '@atlaskit/icon/glyph/search';
import { Base64 } from 'js-base64';
import { API_BASE_URL } from '../App';

function SearchBar({ onSelect, value = '', navigateOnSelect = true }) {
  const [searchQuery, setSearchQuery] = useState(value);
  const [searchResults, setSearchResults] = useState([]);
  const [showDropdown, setShowDropdown] = useState(false);

  useEffect(() => {
    if (searchQuery.length >= 3) {
      const fetchSearchResults = async () => {
        try {
          const filters = { query: searchQuery };
          const encodedFilters = Base64.encodeURL(JSON.stringify(filters));
          const response = await fetch(`${API_BASE_URL}/stock-search/?filters=${encodedFilters}`);
          const data = await response.json();
          setSearchResults(data);
          setShowDropdown(true);
        } catch (error) {
          console.error('Error fetching search results:', error);
        }
      };

      fetchSearchResults();
    } else {
      setSearchResults([]);
      setShowDropdown(false);
    }
  }, [searchQuery]);

  const handleSelect = (symbol) => {
    setSearchQuery(symbol);  // Set the selected symbol in the search bar
    setSearchResults([]);
    setShowDropdown(false);  // Hide the dropdown after selection
    onSelect(symbol);
  };

  const handleBlur = () => {
    setTimeout(() => setShowDropdown(false), 100); // Hide dropdown after a short delay to allow the click to register
  };

  return (
    <div className="search-bar-wrapper">
      <TextField
        placeholder="Search stock..."
        elemAfterInput={<SearchIcon label="Search icon" />}
        aria-label="Search"
        className="search-bar"
        value={searchQuery}
        onChange={(e) => setSearchQuery(e.target.value)}
        onBlur={handleBlur}  // Hide the dropdown when the input loses focus
        onFocus={() => searchQuery.length >= 3 && setShowDropdown(true)}  // Show dropdown when the input gains focus and has at least 3 characters
      />
      {showDropdown && searchResults.length > 0 && (
        <div className="search-results-dropdown">
          <ul>
            {searchResults.map((result) => (
              <li key={result.symbol} onClick={() => handleSelect(result.symbol)}>
                {result.symbol} - {result.name}
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
}

export default SearchBar;