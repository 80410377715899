import React, { useState, useEffect, useMemo } from "react";
import { formatDateTime, API_BASE_URL } from "../App";
import { Link } from "react-router-dom";
import "../index.css";
import "../TableSection.css";

function SignalAnalysis() {
    const [tableData, setTableData] = useState([]);

    // Fields to display in the table (excluding call_id)
    const displayFields = useMemo(() => ["strategy", "signal", "action", "status", "call_sent_at", "link_to_chart"], []);

    // Function to fetch data from the API
    const fetchData = async () => {
        try {
            const response = await fetch(`${API_BASE_URL}/trade-signals`);
            const data = await response.json();
            setTableData(data);
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };

    useEffect(() => {
        // Fetch data initially and then every 5 minutes
        fetchData();
        const intervalId = setInterval(fetchData, 300000);  // Poll every 5 minutes

        return () => clearInterval(intervalId);  // Clean up interval on component unmount
    }, []);

    // Function to render table rows
    const renderTableRows = useMemo(() => {
        return tableData.map((row, rowIndex) => (
            <tr key={rowIndex}>
                {displayFields.map((field, colIndex) => (
                    <td key={colIndex}>
                        {field === "link_to_chart" ? (
                            <Link
                                to={`/charts?call_id=${row.call_id}`}  // Pass call_id as a query parameter
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                View Chart
                            </Link>
                        ) : field === "call_sent_at" ? (
                            formatDateTime(row[field])
                        ) : (
                            row[field]
                        )}
                    </td>
                ))}
            </tr>
        ));
    }, [tableData, displayFields]);

    return (
        <div className="table-section table-section-nowrap">
            <div className="fixed-heading">
                <h2>Signal Analysis</h2>
            </div>
            <div className="table-container">
                <table>
                    <thead>
                        <tr>
                            {displayFields.map((field, index) => (
                                <th key={index}>{field.toUpperCase()}</th>
                            ))}
                        </tr>
                    </thead>
                    <tbody>
                        {tableData.length > 0 ? (
                            renderTableRows
                        ) : (
                            <tr>
                                <td colSpan={displayFields.length}>No Data</td>
                            </tr>
                        )}
                    </tbody>
                </table>
            </div>
        </div>
    );
}

export default SignalAnalysis;