import React, { useState, useEffect, useMemo } from "react";
import { Base64 } from 'js-base64';
import { API_BASE_URL } from "../App";
import "../index.css";
import "../TableSection.css";

// Function to determine the background color based on "colour" property
const getCellStyle = (colour) => {
    if (colour === "positive") {
        return { backgroundColor: "green", color: "white" };  // Green background with white text
    } else if (colour === "negative") {
        return { backgroundColor: "red", color: "white" };  // Red background with white text
    } else {
        return {}; // Default style (no color)
    }
};

function IncomeStatement({ symbol, type = "income_statement", period = "quarterly" }) {
    const [tableData, setTableData] = useState([]);
    const [headers, setHeaders] = useState([]);

    // Memoized API endpoint to avoid unnecessary recalculations
    const apiEndpoint = useMemo(() => {
        if (!symbol) {
            console.error("Symbol is a mandatory parameter");
            return null;
        }

        const filters = {
            symbol: symbol, // Symbol is mandatory
            type: type,
            period: period
        };

        // Convert filters to JSON and encode in base64
        const encodedFilters = Base64.encodeURL(JSON.stringify(filters));
        return `${API_BASE_URL}/financial-data/?filters=${encodedFilters}`;
    }, [symbol, type, period]);

    useEffect(() => {
        let isMounted = true; // To prevent state updates if the component unmounts

        const fetchData = async () => {
            if (!apiEndpoint) return; // Exit early if the API endpoint is not valid

            try {
                const response = await fetch(apiEndpoint);
                const data = await response.json();

                if (isMounted) {  // Only set state if still mounted
                    // Extract headers and data
                    const extractedData = data[0]?.fundamentals?.income_statement?.quarterly?.consolidated?.data || [];
                    const extractedHeaders = data[0]?.fundamentals?.income_statement?.quarterly?.consolidated?.headers || [];

                    setTableData(extractedData);
                    setHeaders(extractedHeaders);
                }
            } catch (error) {
                if (isMounted) {
                    console.error("Error fetching data:", error);
                }
            }
        };

        fetchData();

        return () => {
            isMounted = false; // Clean up to prevent state updates on unmounted component
        };
    }, [apiEndpoint]);  // Only re-fetch if the API endpoint changes

    return (
        <div className="table-section table-section-nowrap">
            <div className="fixed-heading">
                <h2>Income Statement</h2>
            </div>
            <div className="table-container">
                <table>
                    <thead>
                        <tr>
                            {headers.map((header, index) => (
                                <th key={index}>{header}</th>
                            ))}
                        </tr>
                    </thead>
                    <tbody>
                        {tableData.length > 0 ? (
                            tableData.map((row, rowIndex) => (
                                <tr key={rowIndex}>
                                    {headers.map((header, colIndex) => (
                                        <td key={colIndex} style={getCellStyle(row[header]?.colour)}>
                                            {row[header]?.value ?? row[header]}
                                        </td>
                                    ))}
                                </tr>
                            ))
                        ) : (
                            <tr>
                                <td colSpan={headers.length}>No Data</td>
                            </tr>
                        )}
                    </tbody>
                </table>
            </div>
        </div>
    );
}

export default IncomeStatement;