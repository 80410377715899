import React, { useState, useEffect, useMemo } from "react";
import { Base64 } from 'js-base64';
import { API_BASE_URL } from "../App";
import "../index.css";
import "../TableSection.css";

function CompanyInfo({ symbol, indicators = null }) {  // Make symbol a mandatory prop
    const [tableData, setTableData] = useState([]);
    const displayFields = ["indicator", "value"];

    // Construct the API endpoint with encoded filter parameters
    const apiEndpoint = useMemo(() => {
        if (!symbol) {
            console.error("Symbol is a mandatory parameter");
            return null;
        }

        const filters = {
            symbol: symbol  // Ensure symbol is passed as a filter
        };

        // Convert filters to JSON and encode in base64
        const encodedFilters = Base64.encodeURL(JSON.stringify(filters));

        // Return the endpoint with the encoded filters as a query parameter
        return `${API_BASE_URL}/company-info/?filters=${encodedFilters}`;
    }, [symbol]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch(apiEndpoint);
                const data = await response.json();

                // Filter data to match the specified indicators
                const filteredData = indicators.map(indicator => {
                    let value = data[indicator];
                    
                    // Handle nested objects like "NSE" and "technical"
                    if (typeof value === 'object' && value !== null) {
                        // Convert nested object to key-value pairs
                        return Object.entries(value).map(([subKey, subValue]) => ({
                            indicator: `${indicator}.${subKey}`,
                            value: subValue
                        }));
                    } else {
                        return { indicator, value };
                    }
                }).flat(); // Flatten the array to handle nested values
                
                setTableData(filteredData); // Update state with filtered data
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        };

        if (apiEndpoint) {
            fetchData();
        }
    }, [apiEndpoint, indicators]);

    return (
        <div className="table-section">
            <div className="fixed-heading">
                <h2>Company Info</h2>
            </div>
            <div className="table-container">
                <table>
                    <thead>
                        <tr>
                            {displayFields.map((field, index) => (
                                <th key={index}>{field.toUpperCase()}</th>
                            ))}
                        </tr>
                    </thead>
                    <tbody>
                        {tableData.length > 0 ? (
                            tableData.map((row, rowIndex) => (
                                <tr key={rowIndex}>
                                    <td>{row.indicator}</td>
                                    <td>{row.value !== null ? row.value: "N/A"}</td>
                                </tr>
                            ))
                        ) : (
                            <tr>
                                <td colSpan={displayFields.length}>No Data</td>
                            </tr>
                        )}
                    </tbody>
                </table>
            </div>
        </div>
    );
}

export default CompanyInfo;