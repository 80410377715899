import React, { useState, useEffect } from "react";
import { formatDateTime, API_BASE_URL } from "../App";
import "../index.css";
import "../TableSection.css";

const CommodityMetadata = () => {
    const [tableData, setTableData] = useState([]);
    const apiEndpoint = `${API_BASE_URL}/commodity-metadata/`;
    const displayFields = ["symbol", "current_price", "low", "open", "high", "expiry_date", "volume", "updated_at"];

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch(apiEndpoint);
                const data = await response.json();
                setTableData(data);
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        };

        fetchData();
        const intervalId = setInterval(fetchData, 300000);
        return () => clearInterval(intervalId);
    }, [apiEndpoint]);

    return (
        <div className="table-section">
            <div className="fixed-heading">
                <h2>Metadata</h2>
            </div>
            <div className="table-container">
                <table >
                    <thead>
                        <tr>
                            {displayFields.map((field, index) => (
                                <th key={index}>{field.toUpperCase()}</th>
                            ))}
                        </tr>
                    </thead>
                    <tbody>
                        {tableData.length > 0 ? (
                            tableData.map((row, rowIndex) => (
                                <tr key={rowIndex}>
                                    {displayFields.map((field, colIndex) => (
                                        <td style={{ whiteSpace: 'nowrap' }} key={colIndex}>
                                            {["expiry_date", "updated_at"].includes(field) ? formatDateTime(row[field]) : row[field]}
                                        </td>
                                    ))}
                                </tr>
                            ))
                        ) : (
                            <tr>
                                <td colSpan={displayFields.length}>No Data</td>
                            </tr>
                        )}
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default CommodityMetadata;
