import React, { useState, useEffect, useMemo, useCallback } from "react";
import { Base64 } from 'js-base64';
import { formatDateTime, API_BASE_URL } from "../App";
import "../index.css";
import "../TableSection.css";
import { Link } from "react-router-dom";

function TradingCalls({ pageName = "TradingCalls", symbols = null, symbol = null, stage = null, sub_category = null, instrument_type = null }) {
    const [tableData, setTableData] = useState([]);
    const [loading, setLoading] = useState(true);

    // Determine display fields based on the page name
    const displayFields = useMemo(() => {
        if (pageName === 'Holdings') {
            return ["symbol", "call_type", "current_price", "pnl", "executed_price", "target_price1", "stop_loss_price1", "quantity", "order_value", "call_sent_at", "advisor", "sub_category"];
        }
        return ["symbol", "call_type", "current_price", "entry_price1", "target_price1", "stop_loss_price1", "call_sent_at", "advisor", "sub_category", "source", "message"];
    }, [pageName]);

    // Construct the API endpoint with encoded filter parameters
    const apiEndpoint = useMemo(() => {
        const filters = {
            symbols: symbols || undefined, // Convert array to comma-separated string
            symbol: symbol ? [symbol] : undefined,
            stage: stage ? [stage] : undefined,
            sub_category: sub_category ? [sub_category] : undefined,
            instrument_type: instrument_type ? [instrument_type] : undefined
        };

        // Remove undefined keys from the filters object
        Object.keys(filters).forEach(key => filters[key] === undefined && delete filters[key]);

        if (Object.keys(filters).length > 0) {
            const encodedFilters = Base64.encodeURL(JSON.stringify(filters));
            return `${API_BASE_URL}/analyst-recommendations/?filters=${encodedFilters}`;
        }
        return `${API_BASE_URL}/analyst-recommendations/`;
    }, [symbols, symbol, stage, sub_category, instrument_type]);

    const fetchData = useCallback(async () => {
        setLoading(true);
        try {
            const response = await fetch(apiEndpoint);
            const data = await response.json();
            setTableData(data);
        } catch (error) {
            console.error("Error fetching data:", error);
        } finally {
            setLoading(false);
        }
    }, [apiEndpoint]);

    useEffect(() => {
        fetchData();
        const intervalId = setInterval(fetchData, 300000); // Poll every 2 minutes
        return () => clearInterval(intervalId); // Clean up interval on component unmount
    }, [fetchData]);

    if (loading) {
        return <div>Loading...</div>; // Optional: Add a loading spinner
    }

    return (
        <div className="table-section table-section-nowrap">
            <div className="fixed-heading">
                <h2>{pageName}</h2>
            </div>
            <div className="table-container">
                <table>
                    <thead>
                        <tr>
                            {displayFields.map((field, index) => (
                                <th key={index}>{field.toUpperCase()}</th>
                            ))}
                        </tr>
                    </thead>
                    <tbody>
                        {tableData.length > 0 ? (
                            tableData.map((row, rowIndex) => (
                                <tr
                                    key={rowIndex}
                                    // Apply row background color based on PnL if on 'Holdings' page
                                    style={
                                        pageName === "Holdings" && row["pnl"] !== undefined && Math.abs(row["pnl"]) > 1
                                            ? { backgroundColor: row["pnl"] > 0 ? "green" : "red", color: "white" }
                                            : {}
                                    }
                                >
                                    {displayFields.map((field, colIndex) => (
                                        <td key={colIndex}>
                                            {/* Apply color logic for 'call_type' field for non-'Holdings' pages */}
                                            {field === "call_type" && pageName !== "Holdings" ? (
                                                <span style={{ backgroundColor: row[field]?.toLowerCase() === "buy" ? "green" : "red", color: "white" }}>
                                                    {row[field]}
                                                </span>
                                            ) : field === "symbol" ? (
                                                <Link to={`/fundamentals/${row[field]}`} target="_blank">
                                                    {row[field]}
                                                </Link>
                                            ) : field === "call_sent_at" ? (
                                                formatDateTime(row[field])
                                            ) : (
                                                row[field]
                                            )}
                                        </td>
                                    ))}
                                </tr>
                            ))
                        ) : (
                            <tr>
                                <td colSpan={displayFields.length}>No Data</td>
                            </tr>
                        )}
                    </tbody>
                </table>
            </div>
        </div>
    );
}

export default TradingCalls;