import React, { useState, useEffect, useMemo } from "react";
import { Base64 } from 'js-base64';
import { API_BASE_URL } from "../App";
import "../index.css";
import "../TableSection.css";

function FinancialRatiosStatement({ symbol, type = "financial_ratios", period = "annual" }) {
    const [tableData, setTableData] = useState([]);
    const [headers, setHeaders] = useState([]);

    // Predefined indicators to show
    const indicators = useMemo(() => ['BVSH_A', 'ROCE_A', 'DEBT_CE_A', 'NP_A_GROWTH', 'REV1_A', 'NI3_A', 'REV2_A'], []);

    // Create API Endpoint
    const apiEndpoint = useMemo(() => {
        if (!symbol) {
            console.error("Symbol is a mandatory parameter");
            return null;
        }

        const filters = {
            symbol: symbol, // Symbol is mandatory
            type: type,
            period: period
        };

        // Convert filters to JSON and encode in base64
        const encodedFilters = Base64.encodeURL(JSON.stringify(filters));
        return `${API_BASE_URL}/financial-data/?filters=${encodedFilters}`;
    }, [symbol, type, period]);

    useEffect(() => {
        let isMounted = true; // To prevent state updates if the component unmounts

        const fetchData = async () => {
            if (!apiEndpoint) return; // Exit early if the API endpoint is not valid

            try {
                const response = await fetch(apiEndpoint);
                const data = await response.json();

                if (isMounted) {  // Only set state if still mounted
                    // Extract headers and data
                    const extractedData = data[0]?.fundamentals?.financial_ratios?.annual?.consolidated?.data || [];
                    const extractedHeaders = data[0]?.fundamentals?.financial_ratios?.annual?.consolidated?.headers || [];

                    // Filter data based on the indicators
                    const filteredData = extractedData.filter(row => indicators.includes(row.indicator_code));

                    setTableData(filteredData);
                    setHeaders(extractedHeaders);
                }
            } catch (error) {
                if (isMounted) {
                    console.error("Error fetching data:", error);
                }
            }
        };

        fetchData();

        return () => {
            isMounted = false; // Clean up to prevent state updates on unmounted component
        };
    }, [apiEndpoint, indicators]);

    // Function to determine the color based on "colour"
    const getCellStyle = (colour) => {
        if (colour === "positive") {
            return { backgroundColor: "green", color: "white" };  // Green background with white text
        } else if (colour === "negative") {
            return { backgroundColor: "red", color: "white" };  // Red background with white text
        } else {
            return {}; // Default style (no color)
        }
    };

    return (
        <div className="table-section table-section-nowrap">
            <div className="fixed-heading">
                <h2>Ratios</h2>
            </div>
            <div className="table-container">
                <table>
                    <thead>
                        <tr>
                            {headers.map((header, index) => (
                                <th key={index}>{header}</th>
                            ))}
                        </tr>
                    </thead>
                    <tbody>
                        {tableData.length > 0 ? (
                            tableData.map((row, rowIndex) => (
                                <tr key={rowIndex}>
                                    {/* First column for the indicator name */}
                                    <td>{row.indicator}</td>

                                    {/* Remaining columns for the financial data */}
                                    {headers.slice(1).map((header, colIndex) => (
                                        <td
                                            key={colIndex}
                                            style={getCellStyle(row[header]?.colour)}  // Apply color based on the colour code
                                        >
                                            {row[header]?.value !== undefined ? row[header]?.value : "N/A"}
                                        </td>
                                    ))}
                                </tr>
                            ))
                        ) : (
                            <tr>
                                <td colSpan={headers.length}>No Data</td>
                            </tr>
                        )}
                    </tbody>
                </table>
            </div>
        </div>
    );
}

export default FinancialRatiosStatement;