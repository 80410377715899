import React, { useState, useEffect, useMemo, useCallback } from "react";
import { Base64 } from 'js-base64';
import { formatDateTime, API_BASE_URL } from "../App";
import "../index.css";
import "../TableSection.css";

function MarketChat({ symbol = null, instrument_type = null, keywords = null, source = null }) {
    const [tableData, setTableData] = useState([]);
    const [loading, setLoading] = useState(true);

    // Memoized list of fields to display
    const displayFields = useMemo(() => ["message", "created_at", "sender", "source", "link"], []);

    // Construct the final API endpoint with encoded filter parameters
    const finalApiEndpoint = useMemo(() => {
        const filters = { symbol, instrument_type, keywords: keywords || (symbol ? [symbol] : undefined), source };

        // Remove undefined values from the filters
        const filteredFilters = Object.fromEntries(Object.entries(filters).filter(([, value]) => value !== undefined));

        // Convert filters to JSON and encode in base64 if there are filters
        const encodedFilters = Object.keys(filteredFilters).length ? Base64.encodeURL(JSON.stringify(filteredFilters)) : null;

        return `${API_BASE_URL}/market-chat/${encodedFilters ? `?filters=${encodedFilters}` : ""}`;
    }, [symbol, instrument_type, keywords, source]);

    // Function to fetch data
    const fetchData = useCallback(async () => {
        setLoading(true);
        try {
            const response = await fetch(finalApiEndpoint);
            const data = await response.json();
            setTableData(data);  // Update state with fetched data
        } catch (error) {
            console.error("Error fetching data:", error);
        } finally {
            setLoading(false);
        }
    }, [finalApiEndpoint]);

    useEffect(() => {
        if (!finalApiEndpoint) return;

        fetchData();
        const intervalId = setInterval(fetchData, 300000); // Poll every 5 minutes
        return () => clearInterval(intervalId);  // Cleanup interval on unmount
    }, [finalApiEndpoint, fetchData]);

    // Helper function to render each cell content and apply styles
    const renderCell = (field, value) => {
        // Define the styles based on field
        const cellStyle = field !== "message" ? { whiteSpace: 'nowrap' } : {};

        // Determine the content based on field type
        let content;
        switch (field) {
            case "created_at":
                content = formatDateTime(value);
                break;
            case "message":
                content = value || "No message available";  // Handle missing message
                break;
            case "link":
                if (value) {
                    content = <a href={value} target="_blank" rel="noopener noreferrer">🔗</a>;  // Clickable link
                }
                break;
            default:
                content = value;
        }

        return <td style={cellStyle}>{content}</td>;  // Return the table cell with the style applied
    };

    if (loading) {
        return <div>Loading...</div>;  // Optional: Add a loading spinner
    }

    return (
        <div className="table-section">
            <div className="fixed-heading">
                <h2>Market Chats</h2>
            </div>
            <div className="table-container">
                <table>
                    <thead>
                        <tr>
                            {displayFields.map((field, index) => (
                                <th key={index}>{field.toUpperCase()}</th>
                            ))}
                        </tr>
                    </thead>
                    <tbody>
                        {tableData.length > 0 ? (
                            tableData.map((row, rowIndex) => (
                                <tr key={rowIndex}>
                                    {displayFields.map((field, colIndex) => (
                                        renderCell(field, row[field])  // Use the helper function for rendering cells
                                    ))}
                                </tr>
                            ))
                        ) : (
                            <tr>
                                <td colSpan={displayFields.length}>No Data</td>
                            </tr>
                        )}
                    </tbody>
                </table>
            </div>
        </div>
    );
}

export default React.memo(MarketChat);