import React from 'react';
import MyToolbar from './MyToolbar';
import PairTradeStockChart from './widgets/pairTradeStockChart.js';
import { BrowserRouter as Router, Route, Routes, useParams } from 'react-router-dom';
import { pageLayouts } from './pageLayouts';
import { format, toZonedTime } from 'date-fns-tz';
import './index.css';

// config.js or
export const API_BASE_URL = process.env.REACT_APP_API_URL || 'http://localhost:8000/api';
export const timeZone = process.env.REACT_APP_TIMEZONE || 'Asia/Kolkata';

export function formatDateTime(isoDateString) {
  // Convert the UTC date to the specified time zone
  const zonedDate = toZonedTime(new Date(isoDateString+'Z'), timeZone);

  // Format the date in the specified time zone
  return format(zonedDate, 'dd MMM yyyy HH:mm:ss', { timeZone });
}

const Page = ({ layout, passSymbol = false }) => {
  const { symbol } = useParams(); // Extract symbol from the URL params
  const { rows, columns, sheets, largeItems = [] } = layout;

  return (
    <div className="App">
      <main className="grid-container" style={{ '--rows': rows, '--columns': columns }}>
        {sheets.map((Sheet, index) => (
          <div
            key={index}
            className={`grid-item ${largeItems.includes(index) ? 'grid-item-large' : ''}`}
          >
            {/* Pass the symbol prop only if passSymbol is true */}
            {passSymbol && symbol ? React.cloneElement(Sheet, { symbol }) : Sheet}
          </div>
        ))}
      </main>
    </div>
  );
};

function App() {
  return (
    <Router>
      <MyToolbar />
      <Routes>
        <Route path="/" element={<Page layout={pageLayouts[0]} />} />
        <Route path="/portfolio" element={<Page layout={pageLayouts[1]} />} />
        <Route path="/commodity" element={<Page layout={pageLayouts[2]} />} />
        <Route path="/fundamentals/:symbol" element={<Page layout={pageLayouts[3]} passSymbol={true} />} />
        <Route path="/company-info/:symbol" element={<Page layout={pageLayouts[4]} passSymbol={true} />} />
        <Route path="/analysis/:symbol" element={<Page layout={pageLayouts[5]} passSymbol={true} />} />
        <Route path="/screeners" element={<Page layout={pageLayouts[6]} />} />

        <Route path="/charts" element={<PairTradeStockChart />} /> {/* Route updated to handle query params */}
      </Routes>
    </Router>
  );
}

export default App;