import React, { useState, useEffect, useMemo } from "react";
import { Base64 } from 'js-base64';
import { API_BASE_URL } from "../App";
import "../index.css";
import "../TableSection.css";

function ShareholdingReport({ symbol }) {
    const [tableData, setTableData] = useState({});
    const [headers, setHeaders] = useState([]);

    // Construct the API endpoint with encoded filter parameters
    const apiEndpoint = useMemo(() => {
        if (!symbol) {
            console.error("Symbol is a mandatory parameter");
            return null;
        }

        const filters = {
            symbol: symbol  // Ensure symbol is passed as a filter
        };

        // Convert filters to JSON and encode in base64
        const encodedFilters = Base64.encodeURL(JSON.stringify(filters));

        // Return the endpoint with the encoded filters as a query parameter
        return `${API_BASE_URL}/company-info/?filters=${encodedFilters}`;
    }, [symbol]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch(apiEndpoint);
                const data = await response.json();

                // Extract the shareholding data from the response
                const shareholdingData = data.shareholding || {};

                // Extract the time periods (e.g., Jun 2024, Mar 2024, etc.)
                const timePeriods = Object.keys(shareholdingData);
                setHeaders(timePeriods);

                setTableData(shareholdingData);  // Update state with the parsed data
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        };

        if (apiEndpoint) {
            fetchData();
        }
    }, [apiEndpoint]);

    // Function to render rows dynamically for each category and subcategory
    const renderRows = () => {
        return headers.map((period, periodIndex) => {
            const shareholderData = tableData[period];
            return Object.keys(shareholderData).map((key, index) => {
                const holder = shareholderData[key];
                const isCategoryKey = key === holder.sub_category;
                const shareholding_percentage_threshold = 0.5;

                const shareholdingPercentage = holder['shareholding_percentage'];
                if (shareholdingPercentage > shareholding_percentage_threshold) {
                    console.log(shareholdingPercentage)
                    return (
                        <tr key={index}>
                            <td style={isCategoryKey ? { fontWeight: 'bold' } : {}}>{key}</td>
                            {headers.map((header, colIndex) => (
                                <td key={colIndex}>{tableData[header]?.[key]?.['shareholding_percentage']}</td>
                            ))}
                            <td>{holder.sub_category}</td>
                        </tr>
                    );
                }

                // Return null if shareholding_percentage <= 0.05 to skip rendering the row
                return null;
            });
        });
    };

    return (
        <div className="table-section">
            <div className="fixed-heading">
                <h2>Shareholding</h2>
            </div>
            <div className="table-container">
                <table>
                    <thead>
                        <tr>
                            <th>Category & Name of the shareholders</th>
                            {headers.map((header, index) => (
                                <th key={index}>{header}%</th>
                            ))}
                            <th>Category</th>
                        </tr>
                    </thead>
                    <tbody>
                        {Object.keys(tableData).length > 0 ? (
                            renderRows()
                        ) : (
                            <tr>
                                <td colSpan={headers.length + 2}>No Reports Available</td>
                            </tr>
                        )}
                    </tbody>
                </table>
            </div>
        </div>
    );
}

export default ShareholdingReport;