import React, { useState, useEffect } from "react";
import Plot from 'react-plotly.js';
import { Base64 } from 'js-base64';
import { API_BASE_URL } from "../App";
import '../css/ChartSection.css';

function TechnicalPriceMAChart({ symbol }) {
    const [chartData, setChartData] = useState([]);
    const [loading, setLoading] = useState(true);

    const apiUrl = symbol
        ? `${API_BASE_URL}/technical-indicators/?filters=${Base64.encodeURL(JSON.stringify({ symbol }))}`
        : null;

    useEffect(() => {
        const fetchChartData = async () => {
            if (!apiUrl) return;
            setLoading(true);
            try {
                const response = await fetch(apiUrl);
                const data = await response.json();
                setChartData(data.Price_MA); // Assuming Price_MA is part of the API response
            } catch (error) {
                console.error("Error fetching chart data:", error);
            } finally {
                setLoading(false);
            }
        };
        fetchChartData();
    }, [apiUrl]);

    if (loading) return <div>Loading...</div>;

    const timestamps = chartData.map(item => new Date(item.timestamp));
    const closeData = chartData.map(item => item.close);
    const ema5Data = chartData.map(item => item.ema5);
    const ema50Data = chartData.map(item => item.ema50);
    const ema200Data = chartData.map(item => item.ema200);
    const ichimokuData = chartData.map(item => item.ichimoku);

    const lineColors = {
        close: 'blue',
        ema5: 'orange',
        ema50: 'green',
        ema200: 'red',
        ichimoku: 'magenta'
    };

    return (
        <div className="chart-wrapper">
            <Plot
                data={[
                    { x: timestamps, y: closeData, type: 'scatter', mode: 'lines', name: 'Close Price', line: { color: lineColors.close } },
                    { x: timestamps, y: ema5Data, type: 'scatter', mode: 'lines', name: 'EMA 5', line: { color: lineColors.ema5 } },
                    { x: timestamps, y: ema50Data, type: 'scatter', mode: 'lines', name: 'EMA 50', line: { color: lineColors.ema50 } },
                    { x: timestamps, y: ema200Data, type: 'scatter', mode: 'lines', name: 'EMA 200', line: { color: lineColors.ema200 } },
                    { x: timestamps, y: ichimokuData, type: 'scatter', mode: 'lines', name: 'Ichimoku', line: { color: lineColors.ichimoku } }
                ]}
                layout={{
                    title: `${symbol} Price & Moving Averages (hourly)`,
                    xaxis: { type: 'date', tickformat: "%b %d, %H:%M", rangeslider: { visible: false } },
                    yaxis: { title: 'Price' },
                    hovermode: 'x unified',
                    margin: { t: 40, r: 50, l: 50, b: 50 }
                }}
                config={{ responsive: true }}
                style={{ width: '100%', height: '100%' }}
            />
        </div>
    );
}

export default TechnicalPriceMAChart;